import React from "react"
import "../wdyr"
import { navigate } from "gatsby"
import { isLoggedIn } from "../services/auth"

const NetworkingPage = props => {
  const isBrowser = () => typeof window !== "undefined";
  if (isBrowser() && !isLoggedIn()) {
    navigate("/")
    return null;
  }

  return (
    <>
   {(isBrowser() && window.localStorage.hasNetworking === "true" && window.localStorage.isNetworkingEnabled === "true") ? (
        <div style={{width: "100%", height: "100%"}}>
        <iframe style={{width: "100%", height: "100%"}} className="resp-iframe networkingIframe" src={`${process.env.ROCKET_CHAT_URL}/channel/general`} gesture="media"  allow="encrypted-media" allowFullScreens></iframe>
    </div>
      ) : (
        
        <h3 className="d-xs-flex xs-center xs-middle width-full">Feature not available.</h3>
      )}
    
   </>
  )
}

export default NetworkingPage